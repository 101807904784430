#login-logo {
  padding-left: 0px;
  padding-bottom: 20px;
}

#login-div {
  position: absolute;
  top: 20%;
  left: 15%;
  width: 250px;
  height: 200px;
  text-align: center;
  padding: 10px;
}

@media only screen and (max-width: 480px) {
  #login-div {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%) translateY(-20%);
    width: 250px;
    height: 200px;
    text-align: center;
    padding: 10px;
  }
}

#forgot-link {
  display: block;
  color: grey;
}

#login-parent {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  margin: 0 auto;
  background: rgba(0, 35, 50, 1);
  background: -moz-linear-gradient(
    45deg,
    rgba(0, 8, 26, 1) 0%,
    rgba(0, 35, 50, 1) 41%,
    rgba(0, 11, 15, 1) 100%
  );
  background: -webkit-gradient(
    left bottom,
    right top,
    color-stop(0%, rgba(0, 8, 26, 1)),
    color-stop(41%, rgba(0, 35, 50, 1)),
    color-stop(100%, rgba(0, 11, 15, 1))
  );
  background: -webkit-linear-gradient(
    45deg,
    rgba(0, 8, 26, 1) 0%,
    rgba(0, 35, 50, 1) 41%,
    rgba(0, 11, 15, 1) 100%
  );
  background: -o-linear-gradient(
    45deg,
    rgba(0, 8, 26, 1) 0%,
    rgba(0, 35, 50, 1) 41%,
    rgba(0, 11, 15, 1) 100%
  );
  background: -ms-linear-gradient(
    45deg,
    rgba(0, 8, 26, 1) 0%,
    rgba(0, 35, 50, 1) 41%,
    rgba(0, 11, 15, 1) 100%
  );
  background: linear-gradient(
    45deg,
    rgba(0, 8, 26, 1) 0%,
    rgba(0, 35, 50, 1) 41%,
    rgba(0, 11, 15, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00081a', endColorstr='#000b0f', GradientType=1 );
}

/* Change the white to any color */
#login-div input:-webkit-autofill,
#login-div input:-webkit-autofill:hover,
#login-div input:-webkit-autofill:focus,
#login-div input:-webkit-autofill:active {
  background-color: rgb(2 32 45) !important;
  -webkit-box-shadow: 0 0 0 50px rgb(2 32 45) inset;
  -webkit-text-fill-color: white !important;
}
