#material-title-root {
  font-weight: 300;
  display: flex;
  flex-direction: column;
  height: 100%;
}

#material-title-header {
  color: black;
  top: 0;
  width: 100%;
  padding: 8px;
  font-size: 1.5em;
}

#material-title-menu-image {
  height: 30px;
  width: auto;
  border-radius: 50%;
  -webkit-transition: -webkit-transform 0.8s ease-in-out;
  transition: transform 0.8s ease-in-out;
}

#material-title-menu-image:active {
  -webkit-transform: rotate(360deg) scale(1.5, 1.5);
  transform: rotate(360deg) scale(1.5, 1.5);
}
