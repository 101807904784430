body {
  font-family: Roboto, sans-serif;
}

.primary-color {
  color: #00aeca;
}

h4 {
  color: rgba(0, 0, 0, 0.75);
}

.dls-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.dls-wrapper > * {
  flex: auto;
  min-width: calc(100% * (1 / 3) - 10px - 1px);
  max-width: 500px;
}

::-webkit-scrollbar {
  width: 4px; /* for vertical scrollbars */
  height: 4px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 174, 202, 0.8);
}

@-webkit-keyframes autofill {
  to {
    color: #666;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
  -webkit-text-fill-color: #838b95 !important;
}

*:focus {
  outline: none;
}
