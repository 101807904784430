@keyframes buganimation {
  0% {
    font-size: 150px;
    transform: rotate(0deg);
    bottom: 20px;
    left: 50px;
  }
  25% {
    font-size: 200px;
    transform: rotate(95deg);
    top: 20px;
    left: 50px;
  }
  50% {
    font-size: 150px;
    transform: rotate(175deg);
    bottom: 20px;
    left: 50px;
  }
  75% {
    font-size: 200px;
    transform: rotate(280deg);
    bottom: 20px;
    right: 50px;
  }
  100% {
    font-size: 150px;
    transform: rotate(360deg);
    bottom: 20px;
    left: 50px;
  }
}

#bug-icon {
  margin: 60px;
  font-size: 150px;
  color: green;
  position: absolute;
  bottom: 20px;
  left: 50px;
  animation: buganimation 10s infinite;
}

@keyframes downloadanimation {
  0%,
  100% {
    transform: translateX(0%);
    transform-origin: 50% 50%;
  }

  15% {
    transform: translateX(-30px) rotate(-6deg);
  }

  30% {
    transform: translateX(15px) rotate(6deg);
  }

  45% {
    transform: translateX(-15px) rotate(-3.6deg);
  }

  60% {
    transform: translateX(9px) rotate(2.4deg);
  }

  75% {
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

#download-icon {
  margin: 60px;
  font-size: 150px;
  color: #00aeca;
  position: absolute;
  bottom: 60px;
  left: 0px;
  right: 0px;
  animation: downloadanimation 20s ease 0s normal forwards;
}
