.div .drawer-item {
  color: white;
  text-align: left;
}

.sidebar {
  z-index: 10 !important;
  min-height: 100% !important;
  width: 280px;
  overflow-x: hidden;
  background: rgba(0, 35, 50, 1);
  background: -moz-linear-gradient(top, rgba(0, 35, 50, 1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -webkit-gradient(
    left top,
    left bottom,
    color-stop(0%, rgba(0, 35, 50, 1)),
    color-stop(100%, rgba(0, 0, 0, 1))
  );
  background: -webkit-linear-gradient(top, rgba(0, 35, 50, 1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -o-linear-gradient(top, rgba(0, 35, 50, 1) 0%, rgba(0, 0, 0, 1) 100%);
  background: -ms-linear-gradient(top, rgba(0, 35, 50, 1) 0%, rgba(0, 0, 0, 1) 100%);
  background: linear-gradient(to bottom, rgba(0, 35, 50, 1) 0%, rgba(0, 0, 0, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#021417', endColorstr='#000000', GradientType=0 );
  background-color: black !important;
}

#menu-link {
  color: black;
  text-decoration: none;
  padding: 16px;
}

#title-appbar {
  color: black;
  padding-left: 16px;
}

.main-content {
  margin: 20px;
  flex-grow: 1;
}
