#sidebar-logo {
  margin-left: 20px;
  margin-top: 20px;
  margin-right: 20px;
  width: 210px;
  height: auto;
}

#sidebar-menu {
  margin-left: 12px !important;
  max-width: 100% !important;
}

.drawer-icon {
  color: grey !important;
}